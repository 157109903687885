import {safeHtml} from '../../js/util/CommonUtils.js';
import DialogUtils from "../DialogUtils.js";
import {createOrgNameUrlTemplate} from "../../js/util/UrlUtils.js";

export default class UploadInjectionDialogHtml {
    #dialog;
    /** @type {Org} */
    #org;
    #onStopLoadingCallback = () => {};
    #onCreateBatchCallback = () => {};
    #onDownloadErrorCallback = () => {};
    #onShowErrorStackTraceCallback = () => {};

    showProgressBarView() {
        this.remove();
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#progressBarViewTemplate();
        this.#getStopButton().addEventListener('click', this.#onStopButtonClick.bind(this));
        this.#getDialog().showModal();
    }

    setProgressBarPercentsLoaded(percent) {
        this.#dialog.querySelector('.determinate').style.width = percent;
        this.#dialog.querySelector('#percents').textContent = percent;
    }

    showExtractionView() {
        this.remove();
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#extractionViewTemplate();
        this.#getCloseButton().addEventListener('click', this.#onCloseButtonClick.bind(this));
        this.#getDialog().showModal();
    }

    showImportResultView(importResult, org) {
        this.remove();
        this.#org = org;
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#importResultTemplate(importResult, this.#org);
        this.#getDialog().showModal();
        const showMoreButton = this.#getShowMoreButton();
        if (showMoreButton)
            showMoreButton.addEventListener('click', this.#onShowMoreButtonClick.bind(this));
        const showErrorButton = this.#getShowErrorButton();
        if (showErrorButton)
            showErrorButton.addEventListener('click', this.#onShowErrorStackTraceClick.bind(this));
        this.#getCloseButton().addEventListener('click', this.#onCloseButtonClick.bind(this));
        const openInjectionListButton = this.#getOpenInjectionListButton();
        if (openInjectionListButton)
            openInjectionListButton.addEventListener('click', this.#onopenInjectionListButtonClick.bind(this, this.#org));
        const createButton = this.#getCreateButton();
        if (createButton)
            createButton.addEventListener('click', this.#onCreateBatchButtonClick.bind(this));
        const downloadErrorIcon = this.#getDownloadErrorButton();
        if (downloadErrorIcon)
            downloadErrorIcon.addEventListener('click', this.#onDownloadErrorClick.bind(this, importResult.lastError));
        const showErrorPromptBtn = this.#getShowErrorPromptBtn();
        if (showErrorPromptBtn)
            showErrorPromptBtn.addEventListener('click', () => {
                this.#getErrorPromptBlock().classList.toggle('visually-hidden')
            })
    }

    onStopLoading(cb) {
        this.#onStopLoadingCallback = cb;
    }

    onCreateBatch(cb) {
        this.#onCreateBatchCallback = cb;
    }

    /** @param {function(string)} cb */
    onDownloadError(cb) {
        this.#onDownloadErrorCallback = cb;
    }

    /** @param {function(string)} cb */
    onShowErrorStackTrace(cb) {
        this.#onShowErrorStackTraceCallback = cb;
    }

    remove() {
        if (this.#dialog) {
            this.#dialog.remove();
            this.#dialog = null;
        }
    }

    #onStopButtonClick() {
        this.#onStopLoadingCallback();
        this.remove();
    }

    #onCloseButtonClick() {
        this.remove();
    }

    #onShowMoreButtonClick(evt) {
        evt.preventDefault();
        this.#getDialog().querySelector('ul.visually-hidden').classList.remove('visually-hidden');
        this.#getDialog().querySelector('#show-more').remove();
    }

    /**
     * @param {Org} org
     */
    #onopenInjectionListButtonClick(org) {
        const orgTemplate = createOrgNameUrlTemplate(org ? org.name : '');
        window.location.href = `${orgTemplate}/injections`
    }

    #onCreateBatchButtonClick() {
        this.remove();
        this.#onCreateBatchCallback();
    }

    /** @param {string} lastError */
    #onDownloadErrorClick(lastError){
        this.#onDownloadErrorCallback(lastError)
    }

    #onShowErrorStackTraceClick(){
        this.#onShowErrorStackTraceCallback()
    }

    #getShowMoreButton() {
        return this.#dialog.querySelector('#show-more');
    }

    #getShowErrorButton() {
        return this.#dialog.querySelector('#show-error');
    }

    #getStopButton() {
        return this.#dialog.querySelector('.button[data-action="stop"]');
    }

    #getCloseButton() {
        return this.#dialog.querySelector('.button[data-action="close"]');
    }

    #getOpenInjectionListButton() {
        return this.#dialog.querySelector('.button[data-action="openInjectionList"]');
    }

    #getCreateButton() {
        return this.#dialog.querySelector('.button[data-action="create"]');
    }

    /** @returns {HTMLElement} */
    #getDownloadErrorButton() {
        return this.#dialog.querySelector('[data-action="download-error"]');
    }

    /** @returns {HTMLElement} */
    #getShowErrorPromptBtn() {
        return this.#dialog.querySelector('[data-action="show-error-prompt"]');
    }

    /** @returns {HTMLElement} */
    #getErrorPromptBlock() {
        return this.#dialog.querySelector('.upload-injection-dialog__error-prompt');
    }

    #getDialog() {
        if (!this.#dialog) {
            const dialog = document.createElement('dialog');
            dialog.classList.add('upload-injection-dialog');
            document.body.appendChild(dialog);
            this.#dialog = dialog;
            this.#addListeners();
        }
        return this.#dialog;
    }

    #addListeners(){
        DialogUtils.setDefaultDialogListeners(this.#dialog)
        this.#dialog.addEventListener('close', this.remove.bind(this));
    }

    static #progressBarViewTemplate() {
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">Uploading...</span>
                        <span id="percents"></span>
                        <div class="progress">
                            <div class="determinate"></div>
                        </div>
                    </div>
                </div>
                <div class="dialog-buttons">
                    ${this.#secondaryButton('Stop', 'stop', 'autofocus')}
                </div>`;
    }

    static #extractionViewTemplate() {
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">Extracting...</span>
                    </div>
                </div>
                <div class="dialog-buttons">
                    ${this.#secondaryButton('Close', 'close', 'autofocus')}
                </div>
        `;
    }

    static #importResultTemplate(importResult, org) {
        const {successInjectionNames, failedDiscoveredInjections} = importResult;
        const total = successInjectionNames.length + failedDiscoveredInjections.length;
        if (total === 0)
            return UploadInjectionDialogHtml.#emptyImportResultTemplate();
        return this.#injectionListTemplate(org, importResult);
    }

    static #emptyImportResultTemplate() {
        return `
            <div class="dialog-content">
                <p>No injections found inside ZIP. Possible reasons:</p>
                <ul class="upload-injection-dialog__error-list">
                    <li>The vendor isn't supported, see <a target="_blank" href="https://elsci.io/docs/peaksel/dataimport/vendor-formats.html">Vendors & Formats</a></li>
                    <li>The file structure is incorrect, check <a target="_blank" href="https://elsci.io/docs/peaksel/dataimport/import-manual-upload.html">documentation</a></li>
                    <li>You didn't zip any injection files <span>&macr;\\_(&#x30C4;)_/&macr;</span></li>
                </ul>
                <p>If you need more help, get it touch: support@elsci.io</p>
            </div>
            <div class="dialog-buttons">
                ${this.#secondaryButton('Close', 'close', 'autofocus')}
            </div>`;
    }

    static #injectionListTemplate(org, importResult) {
        const {successInjectionNames, failedDiscoveredInjections, lastError} = importResult;
        const total = successInjectionNames.length + failedDiscoveredInjections.length;
        const list = this.#createListEntries(org, importResult);
        const visibleListHtml = `<ul>${list.slice(0, UploadInjectionDialogHtml.#VISIBLE_ELEMENTS_COUNT).join('')}</ul>`;
        const hiddenListElements = list.slice(UploadInjectionDialogHtml.#VISIBLE_ELEMENTS_COUNT).join('');
        let optionalListHtml = '', optionalErrorButtonHtml = '';
        if (lastError) {
            optionalErrorButtonHtml = `
                <div>
                    <p>An error happened, see the <button id="show-error" class="button--link">details</button> and the <button class="button--link" data-action="show-error-prompt">instructions</button>.</p>
                    <div class="upload-injection-dialog__error-prompt visually-hidden">
                        Possible reasons for the error:
                        <ul class="upload-injection-dialog__error-list">
                            <li>The vendor isn't supported, see <a target="_blank" href="https://elsci.io/docs/peaksel/dataimport/vendor-formats.html">Vendors & Formats</a></li>
                            <li>You may be using a very old or a very new version of the vendor software</li>
                            <li>We don't support some detector feature</li>
                        </ul>
                        <p>We'll fix it, just email us the error 
                        (<span data-action="download-error" class="button--link">download</span>), 
                        along with the vendor file if possible: support@elsci.io</p>
                    </div>
                </div>`;
        }
        let closeButtonHtml, openInjectionListButtonHtml, createButtonHtml;
        switch (successInjectionNames.length) {
            case 0:{
                closeButtonHtml = this.#secondaryButton('Close', 'close', 'autofocus');
                openInjectionListButtonHtml = '';
                createButtonHtml = '';
                break;
            }
            case 1: {
                closeButtonHtml = this.#secondaryButton('Close', 'close');
                openInjectionListButtonHtml = this.#primaryButton('Show in list', 'openInjectionList', 'autofocus');
                createButtonHtml = this.#secondaryButton('Create batch', 'create');
                break;
            }
            default:{
                closeButtonHtml = this.#secondaryButton('Close', 'close');
                openInjectionListButtonHtml = this.#secondaryButton('Show in list', 'openInjectionList');
                createButtonHtml = this.#primaryButton('Create batch', 'create', 'autofocus');
            }
        }
        if (hiddenListElements.length > 0) {
            optionalListHtml = `<button id="show-more" class="button--link">Show all</button>
                            <ul class="visually-hidden">
                                ${hiddenListElements}
                            </ul>`;
        }
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">${successInjectionNames.length} injection(s) out of ${total} were uploaded successfully.</span>
                    </div>
                    <div class="dialog-row">
                        ${UploadInjectionDialogHtml.#SVG_DEFS + visibleListHtml + optionalListHtml}
                    </div>
                    ${optionalErrorButtonHtml}
                </div>
                <div class="dialog-buttons">
                        ${createButtonHtml} ${openInjectionListButtonHtml} ${closeButtonHtml}
                </div>`;
    }
    static #createListEntries(org, importResult){
        const {successInjectionNames, failedDiscoveredInjections, successInjectionIds} = importResult;
        const orgTemplate = org ? createOrgNameUrlTemplate(org.name) : "";
        const arr1 = [];
        for (let i = 0; i < successInjectionNames.length; i++) {
            arr1.push(safeHtml`<li class="upload-injection-dialog__list-item">
                        <span class="upload-injection-dialog__list-item-icon">
                            <svg fill-opacity=".7" viewBox="0 0 24 24" width="18px">
                                <use href="#status-parsed"></use>
                            </svg>			
					    </span>
					    <a href="${orgTemplate}/injection/${successInjectionIds[i]}">${successInjectionNames[i]}</a>
				</li>`)
        }
        const arr2 = failedDiscoveredInjections.map((name) =>{
            return safeHtml`<li>
                        <span>
                            <svg fill-opacity=".7" viewBox="0 0 24 24" width="18px">
                                <use href="#status-parsing_failed"></use>
                            </svg>			
					    </span>
					    <span>${name}</span>
				</li>`
        });
        return arr1.concat(arr2);
    }

    static #primaryButton(name, action, attributes = '') {
        return this.#button('button--contained', name, action, attributes);
    }

    static #secondaryButton(name, action, attributes = '') {
        return this.#button('button--text', name, action, attributes);
    }

    static #button(classList, name, action, attributes = '') {
        return `<button class="button ${classList}" ${attributes} data-action="${action}">${name}</button>`
    }

    static #VISIBLE_ELEMENTS_COUNT = 3;
    static #SVG_DEFS = `
        <svg style="display: none">
            <defs>
                <g fill="rgb(0,128,0)" id="status-parsed">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                </g>
                <g fill="rgb(207,42,39)" id="status-parsing_failed">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                </g>
            </defs>
        </svg>`;
}

