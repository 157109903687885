export default class PivotedPeak {
    /**
     * @param {PivotedSubstance} substance
     * @param {PivotedChromatogram} chromatogram
     * @param {number} area
     * @param {number} areaPercent
     * @param {number} rt
     * @param {boolean} largestPeak
     * @param {number} base
     * @param {number} start
     * @param {number} end
     * @param {string} id
     * @param {boolean} isManual
     */
    constructor(substance, chromatogram, area, areaPercent,
                rt, largestPeak, base, start, end, id, isManual) {
        this.id = id;
        this.substance = substance;
        this.chromatogram = chromatogram;
        this.area = area;
        this.areaPercent = areaPercent;
        this.rt = rt;
        this.largestPeak = largestPeak;
        this.base = base;
        this.start = start;
        this.end = end;
        this.isManual = isManual
    }
}