import BaseDialogHtml from './BaseDialogHtml.js';
import {safeText} from '../../js/util/CommonUtils.js';

export default class BaseDialogCmp {
    #html = new BaseDialogHtml();
    #properties = {
        title: '',
        cssClass: 'base-dialog',
        btnOk: "Ok",
        btnCancel: "Cancel",
        autofocusEl: null,
        btnSecondary: null,
    }

    /**
     * @param {string} [title]
     * @param {string} [cssClass]
     * @param {string|null} [btnOk]
     * @param {string|null} [btnCancel]
     * @param {string|null} [autofocusEl] - CSS class of an element to be autofocused when dialog is shown.
     * @param {string} btnSecondary - when we need one more action in dialog, for example "Submit" btn
     */
    constructor({title = "", cssClass = "base-dialog", btnOk = "Ok", btnCancel = "Cancel",
                    autofocusEl, btnSecondary} = {}) {
        this.#properties.title = title;
        this.#properties.cssClass = cssClass;
        this.#properties.btnOk = btnOk;
        this.#properties.btnCancel = btnCancel;
        this.#properties.autofocusEl = autofocusEl;
        this.#properties.btnSecondary = btnSecondary;
    }

    showText(text) {
        const bodyHtml = `
            <main class="dialog__main-content dialog__main-content--text-only">${safeText(text)}</main>`;
        this.showHtml(bodyHtml);
    }

    showErrorStackTrace(stackTraceText) {
        const bodyHtml = `
            <main class="dialog__main-content dialog__main-content--stack-trace-only">${safeText(stackTraceText)}</main>`;
        this.showHtml(bodyHtml);
    }

    showHtml(bodyHtml) {
        const {title, cssClass, btnOk, btnCancel, autofocusEl,
            btnSecondary} = this.#properties;
        this.#html.show({title, bodyHtml, cssClass, btnOk,
            btnCancel, autofocusEl, btnSecondary});
    }

    /**
     * @return {boolean} if this dialog is shown at the moment
     */
    isAlreadyShown() {
        return this.#html.isAlreadyShown();
    }

    onOk(callback) {
        this.#html.onOk(callback);
    }

    onCancel(callback) {
        this.#html.onCancel(callback);
    }

    /** @param {function} callback */
    onSecondary(callback) {
        this.#html.onSecondary(callback);
    }
}