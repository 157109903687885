import DialogUtils from "../DialogUtils.js";
import {createFileAndTriggerDownload} from "../../js/util/UrlUtils.js";

export default class DownloadErrorDialog {
    /** @type {HTMLDialogElement} */
    #dialog;

    /**
     * @param {string} errorText
     * @param {string} fileName
     */
    show(errorText, fileName){
        this.#dialog = document.body.insertAdjacentElement("beforeend", this.#createDialogElement());
        this.#addEventListeners(errorText, fileName);
        this.#dialog.showModal();
        this.#getOkBtn().focus();
    }

    remove() {
        this.#dialog.remove();
    }

    /**
     * @param {string} errorText
     * @param {string} fileName
     */
    #addEventListeners(errorText, fileName){
        DialogUtils.setDefaultDialogListeners(this.#dialog);
        const icon = this.#getDownloadBtn();
        icon.addEventListener('click', this.#downloadError.bind(this, errorText, fileName));
        const okBtn = this.#getOkBtn();
        okBtn.addEventListener('click', this.remove.bind(this))
    }

    /**
     * @param {string} errorText
     * @param {string} fileName
     */
    #downloadError(errorText, fileName){
        createFileAndTriggerDownload(errorText, fileName, "text/plain")
    }

    /** @returns {HTMLElement} */
    #getOkBtn(){
        return this.#dialog.querySelector('.btn-ok');
    }

    /** @returns {HTMLElement} */
    #getDownloadBtn(){
        return this.#dialog.querySelector('[data-action="download-error"]');
    }

    /** @returns {HTMLDialogElement} */
    #createDialogElement() {
        const el = document.createElement("dialog");
        el.classList.add('download-error-dialog');
        el.innerHTML = this.#template();
        return el;
    }

    /** @returns {string} */
    #template(){
        return `
            <div class="dialog-content">
               <p class="download-error-dialog__text">
               Does this happen again if you repeat the action? Or maybe you see these errors often? <br> 
               Send us (support@elsci.io) the error details, including: </p>
               <ul class="download-error-dialog__list">
                 <li>Error log (<button data-action="download-error" class="button--link">download</button>)
                 <li>Screenshot of the browser page (optional)</li>
                 <li>Steps to reproduce the problem (optional)</li>
               </ul>
            </div>
            <div class="dialog-buttons-row">
                <button class="button button--text btn-ok">Close</button>
            </div>
        `
    }
}