import {safeText} from "./CommonUtils.js";
import OrgSettingsPageViewMode from "../../pages/org-settings/OrgSettingsPageViewMode.js";

export function extractOrgNameFromUrl() {
    const currentUrl = window.location.href;
    const match = currentUrl.match(/\/a\/([a-z0-9_-]+)/);
    if (!match) return null
    return match[1];
}

export function createOrgNameUrlTemplate(name){
    if (!name || name.trim().length === 0)
        return '';
    return `/a/${safeText(name)}`
}

/**
 * @param {string} paramName
 * @param {string} paramValue
 * */
export function addParamToURL(paramName, paramValue) {
    const url = new URL(window.location.href);
    // we don't want to push to history screen=team, because this mode doesn't have own page and is used only with team-id
    if (paramName === "screen" && paramValue === OrgSettingsPageViewMode.NAMES.TEAM.name)
        return
    url.searchParams.set(paramName, paramValue);
    if (paramName === "team-id") {
        // we push to history screen=team and team-id at the same time
        url.searchParams.set("screen", OrgSettingsPageViewMode.NAMES.TEAM.name);
        url.searchParams.set(paramName, paramValue);
    }
    window.history.pushState(null, null, url);
}

/** @param {string} paramName */
export function removeParamFromURL(paramName) {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    window.history.replaceState(null, null, url);
}

export function isFeatureEnabled(featureName){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has(featureName)
}

/**
 * @param {string} errorMessage
 * @param {string} fineName
 * @param {string} type
 */
export function createFileAndTriggerDownload(errorMessage, fineName, type) {
    const blob = new Blob([errorMessage], {type: type});
    const a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fineName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove()
}