import BaseDialogCmp from "../../../components/BaseDialog/BaseDialogCmp.js";
import {safeText} from "../../util/CommonUtils.js";
import DownloadErrorDialog from "../../../components/DownloadErrorDialog/DownloadErrorDialog.js";

export default class HttpErrorHandler {
    /**
     * @type {BaseDialogCmp}
     */
    #signInPopup;
    /**
     * @type {BaseDialogCmp}
     */
    #accessDeniedPopup;
    /**
     * @type {BaseDialogCmp}
     */
    #stackTracePopup;
    /**
     * @type {BaseDialogCmp}
     */
    #notFoundPopup;
    /**
     * @type {BaseDialogCmp}
     */
    #internalServerPopup;

    constructor() {
        this.#signInPopup = new BaseDialogCmp({btnCancel: "Close", btnOk: "Sign in"});
        this.#accessDeniedPopup = new BaseDialogCmp({btnCancel: null, btnOk: "Refresh"});
        this.#stackTracePopup = new BaseDialogCmp({btnCancel: "Close", btnOk: null});
        this.#notFoundPopup = new BaseDialogCmp({btnCancel: null, btnOk: "Refresh"});
        this.#internalServerPopup = new BaseDialogCmp({btnSecondary: "Submit", btnOk: "Close", btnCancel: null});
    }

    /**
     * @param {HttpError} error
     */
    handle(error) {
        if (error.isUserSessionExpired)
            this.#handleSessionExpired(error);
        else if (error.isUnauthorized)
            this.#handleUnauthorized(error);
        else if (error.isForbidden)
            this.#handleForbidden(error);
        else if (error.isBadRequest)
            this.#handleBadRequest(error)
        else if (error.isInternalServerError)
            this.handleInternalServerError(this.#getStackTrace(error), error.securityContext.getCurrentUser());
        else if (error.isNotFound)
            this.#handleIsNotFound(error)
    }

    /**
     * @param {string} errorText
     * @param {UserWithOrgs} user
     */
    handleInternalServerError(errorText, user) {
        if (this.#internalServerPopup.isAlreadyShown())
            return;
        this.#internalServerPopup.showErrorStackTrace(errorText);
        this.#internalServerPopup.onSecondary(this.#showDownloadErrorDialog.bind(this, errorText, user))
    }

    /**
     * Page loads & we're anonymous
     * GET returns 401 -> You need to sign in (button: Sign in).
     *
     * Page loads, we're logged out in the process
     * GET/PUT/PATCH/POST return 401 -> Your session has expired. (button: Sign in)
     *
     * @param {HttpError} error
     */
    #handleUnauthorized(error) {
        if (this.#signInPopup.isAlreadyShown())
            return;
        this.#signInPopup.onOk(() => {
            window.location.href = error.securityContext.signInUrl;
        });
        this.#signInPopup.showText("You need to sign in.");
    }

    /**
     * Page loads -> we're logged in but don't have access
     * GET returns 403 -> You don't have access (button Main page)
     * POST/PUT/DELETE/PATCH return 403 -> You don't have access (button Close)
     *
     * Page loads -> our access is revoked while we're using it
     * Any request returns 403 -> You don't have access (button Close)
     *
     * @param {HttpError} error
     */
    #handleForbidden(error) {
        if (this.#accessDeniedPopup.isAlreadyShown())
            return;
        this.#accessDeniedPopup.onOk(() => {
            window.location.href = error.securityContext.signInUrl;
        });
        const errorDetails = error.response.errorMessage ? `Error details: ${error.response.errorMessage}` : '';
        this.#accessDeniedPopup.showText(`Your permissions have changed and aren't sufficient to perform the
         operation anymore. Refresh the page to see the up-to-date information. ${errorDetails}`);
    }

    #handleBadRequest(error) {
        if (this.#stackTracePopup.isAlreadyShown())
            return;
        if (error.response.errors.length) {
            this.#stackTracePopup.showHtml(this.#badRequestTemplate(error.response.errors));
            return;
        }
        let message = error.response.errorMessage;
        if (!message)
            message = this.#getStackTrace(error);
        this.#stackTracePopup.showText(message);
    }

    #handleIsNotFound(error) {
        if (this.#notFoundPopup.isAlreadyShown())
            return;
        this.#notFoundPopup.onOk(() => {
            location.reload()
        });
        this.#notFoundPopup.showText(this.#getErrorMessage(error));
    }

    #handleSessionExpired(error) {
        if (this.#signInPopup.isAlreadyShown())
            return;
        this.#signInPopup.onOk(() => {
            window.location.href = error.securityContext.signInUrl;
        });
        this.#signInPopup.showText("Your session has expired.");
    }

    /**
     * @param {HttpError} error
     * @returns {string}
     */
    #getStackTrace(error) {
        const response = error.response;
        let errorJson;
        if (response instanceof ArrayBuffer) {
            errorJson = JSON.parse(new TextDecoder().decode(response));
        } else {
            switch (error.responseType) {
                case 'json': {
                    errorJson = response;
                    break;
                }
                case 'arraybuffer': {
                    errorJson = JSON.parse(new TextDecoder().decode(response));
                    break;
                }
                default: {
                    throw new Error('Unsupported response type: ' + response.responseType);
                }
            }
        }
        return errorJson.stackTrace;
    }

    /**
     * @param {HttpError} error
     * @returns {string}
     */
    #getErrorMessage(error) {
        const response = error.response;
        let errorJson;
        if (response instanceof ArrayBuffer) {
            errorJson = JSON.parse(new TextDecoder().decode(response));
        } else {
            switch (error.responseType) {
                case 'json': {
                    errorJson = response;
                    break;
                }
                case 'arraybuffer': {
                    errorJson = JSON.parse(new TextDecoder().decode(response));
                    break;
                }
                default: {
                    throw new Error('Unsupported response type: ' + response.responseType);
                }
            }
        }
        return errorJson.errorMessage;
    }

    /** @param {{detailedMessage: string, message: string, field: string}[]}errors */
    #badRequestTemplate(errors) {
        return `
            <ul>
                ${errors.map(e => {
                    const text = e.detailedMessage || e.message
                    return `<li class="bad-request-dialog__list-item">${safeText(text)}</li>`
                }).join('')}
            </ul>`

    }

    /**
     * @param {string} errorText
     * @param {UserWithOrgs} user
     */
    #showDownloadErrorDialog(errorText, user){
        const userName = user ? user.basicUser.name : "Anonymous";
        const fileName = `${userName}-${new Date().toISOString()}-error.log`;
        const dialog = new DownloadErrorDialog();
        dialog.show(errorText, fileName)
    }
}