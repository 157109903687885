import UploadInjectionDialogHtml from "./UploadInjectionDialogHtml.js";

export default class UploadInjectionDialogCmp {
    /** @type {UploadInjectionDialogHtml} */
    #html;
    #onCreateBatchCallback = () => {};
    #importResult;
    constructor() {
        this.#html = new UploadInjectionDialogHtml();
    }

    onStopLoading(cb) {
        this.#html.onStopLoading(cb);
    }

    onCreateBatch(cb) {
        this.#html.onCreateBatch(()=>{
            cb(this.#importResult.successInjectionIds);
        });
    }

    /** @param {function(string)} cb */
    onDownloadError(cb){
        this.#html.onDownloadError(cb)
    }

    /** @param {function(string)} cb */
    onShowErrorStackTrace(cb){
        this.#html.onShowErrorStackTrace(cb)
    }

    showProgressBarView() {
        this.#html.showProgressBarView();
    }

    updateLoadingProgress(loaded, total) {
        const percent = Math.floor((loaded / total) * 100) + '%';
        if (loaded < total)
            this.#html.setProgressBarPercentsLoaded(percent);
        else
            this.#html.showExtractionView();
    }

    showImportResultView(importResult, org) {
        this.#importResult = importResult;
        this.#html.showImportResultView(importResult, org);
    }

    close() {
        this.#html.remove();
    }
}