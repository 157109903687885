export default class PivotedChromatogram {
    constructor(name, area, isTotal) {
        this.name = name;
        this.area = area;
        this.isTotal = isTotal;
    }

    isMs(){
        return this.name.startsWith('MS')
    }
}