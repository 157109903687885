import FileDropAreaCmp from '../../components/file-drop/FileDropAreaCmp.js';
import NotificationDialogCmp from "../../components/notification-dialog/NotificationDialogCmp.js";
import UploadInjectionDialogCmp from "../../components/UploadInjectionDialog/UploadInjectionDialogCmp.js";
import CreateBatchDialog from "../components/dialog/CreateBatchDialog.js";
import OperationNotAllowedError from "../entities/error/OperationNotAllowedError.js";
import Messages from "../util/Messages.js";
import AccessLevel from "../entities/AccessLevel.js";
import {createFileAndTriggerDownload, createOrgNameUrlTemplate} from "../util/UrlUtils.js";
import HttpError from "../entities/error/HttpError.js";
import HttpErrorHandler from "../entities/error/HttpErrorHandler.js";
import BaseDialogCmp from "../../components/BaseDialog/BaseDialogCmp.js";
import {safeText} from "../util/CommonUtils.js";

export default class UploadInjectionController {
	#progressDialog = new UploadInjectionDialogCmp();
	/** @type {BatchRepository} */
	#batchRepository;
	/** @type {Org} */
	#org;
	/** @type {SecurityContext} */
	#securityContext;

	/**
	 * @param {InjectionRepository} injectionRepository
	 * @param {GlobalSettingsRepository} globalSettingsRepository
	 * @param {BatchRepository} batchRepository
	 * @param {SecurityContext} securityContext
	 * @param {Org} org
	 */
	constructor(injectionRepository, globalSettingsRepository, batchRepository,
				org, securityContext) {
		this._messages = {
			NOT_A_ZIP: 'Seems like it is not a zip file',
		};
		this._injectionRepo = injectionRepository;
		this._globalSettingsRepository = globalSettingsRepository;
		this.#batchRepository = batchRepository;
		this.#org = org;
		this.#securityContext = securityContext;
		this._fileDropArea = new FileDropAreaCmp();
	}

	onDrop(cb){
		this._fileDropArea.onDrop(cb)
	}

	/** @param {HTMLElement} parent */
	init(parent) {
		this._fileDropArea.init(parent, {message: "To upload sample drop your ZIP file here"}
		);
		this._fileDropArea.onDrop(async (files)=>{
			if (!this.#securityContext.isAuthenticated()) {
				throw new HttpError({request: {status: 401}}, this.#securityContext)
			}
			if (this.#org && this.#org.userAccessLevel.lessThan(AccessLevel.CREATE)) {
				throw new OperationNotAllowedError(1, Messages.INJECTION.LOW_ACCESS_LEVEL);
			} else {
				await this.onFileDrop(files[0]);
			}
		})
	}

	async onFileDrop(file) {
		const maxUploadMb = await this._globalSettingsRepository.getMaxUploadMb();
		if (!maxUploadMb)
			throw new Error('Invalid max upload MB: ' + maxUploadMb);
		let error = null;
		if (!file.type.includes('zip'))
			error = this._messages.NOT_A_ZIP;
		else if (UploadInjectionController._bytesToMb(file.size) > maxUploadMb)
			// Note that we're checking a size of the compressed file here, not the uncompressed data inside of it.
			// This is a preliminary check - if the compressed version is over the limit, then uncompressed
			// data will most likely be ever larger. But it's possible that this check passes, and then BE
			// will respond with an error with slightly different message.
			error = `<p>File was too big, max <em>uncompressed</em> size: ${maxUploadMb}MB</p>`;
		if (error) {
			new NotificationDialogCmp().showUnsafe('', error);
		} else
			await this.startFileUpload(file);
	}

	async startFileUpload(file) {
		this.#progressDialog.showProgressBarView();
		const {cancelRequest, promise} = this._injectionRepo.uploadInjection(this.#orgId(), file, (loaded, total) => {
			this.#progressDialog.updateLoadingProgress(loaded, total);
		});
		this.#progressDialog.onStopLoading( () => {
			cancelRequest();
		});
		this.#progressDialog.onDownloadError(this.#createFileWithErrorAndTriggerDownload.bind(this));
		this.#progressDialog.onCreateBatch(async (injectionIds) => {
			const dialog = new CreateBatchDialog()
				.render('', injectionIds.length);
			dialog.on('create', async (formData) => {
				const batchId = await this.#batchRepository.reassignBatch(injectionIds, {name: formData.batchName});
				const org = createOrgNameUrlTemplate(this.#orgName());
				document.location.assign(`${org}/batch/${batchId}`);
			})
		});
		try {
			const response = await promise;
			this.#progressDialog.showImportResultView(response, this.#org);
			this.#progressDialog.onShowErrorStackTrace(this.#onShowErrorStackTrace.bind(this, response.lastError));
			return response; // for testing
		} catch (reason) {
			const httpError = new HttpError({request: reason.request}, this.#securityContext);
			this.#progressDialog.close();
			if (httpError.isOrgSpaceQuotaReached)
				this.#showQuotaReachedDialog();
			else
				throw httpError;
		}
	}

	#orgId() {
		return this.#org.id;
	}

	#orgName() {
		return this.#org.name;
	}

	#onShowErrorStackTrace(errorText){
		const errorHandler = new HttpErrorHandler();
		errorHandler.handleInternalServerError(errorText, this.#securityContext.getCurrentUser())
	}

	#showQuotaReachedDialog(){
		const dialog = new BaseDialogCmp({btnCancel: null, btnOk: "Ok"});
		dialog.showHtml(`
			<div class="dialog__main-content"><b>${safeText(this.#org.displayName)}</b> ran out of space, to change the limits visit 
			<a target="_blank" href="${createOrgNameUrlTemplate(this.#org.name)}/settings?screen=billing">Billing & Quotas</a> page</div>
		`)
	}

	/** @param {string} errorMessage */
	#createFileWithErrorAndTriggerDownload(errorMessage) {
		const fileName = `${this.#securityContext.getCurrentUser().basicUser.name}-${new Date().toISOString()}-error.log`
		createFileAndTriggerDownload(errorMessage, fileName, "text/plain")
	}

	static _bytesToMb(bytes) {
		return bytes / (1 << 20);
	}
}
